import { Link } from 'gatsby'
import React from 'react'

import Footer from 'components/footer/index'

import logo from '../dist/img/logo/logo_white.png'

const BlogIndex = ({ location }) => {
  return (
    <div>
      <div
        className="vertical-center fullheight text-center"
        style={{ backgroundColor: `#1E1E1E` }}
      >
        <div className="container">
          <img src={logo} />
          <div>
            <Link
              role="button"
              className="btn btn-light"
              to="/en/"
              style={{ marginRight: `1rem` }}
            >
              Hello!
            </Link>
            <Link
              role="button"
              className="btn btn-light"
              to="/de/"
              style={{ marginLeft: `1rem` }}
            >
              Hallo!
            </Link>
          </div>
        </div>
      </div>
      <Footer data={location} />
    </div>
  )
}

export default BlogIndex
